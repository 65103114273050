import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/app";
import "firebase/functions";
import "firebase/firebase-functions";
import "firebase/analytics";

var firebaseConfig = {
    apiKey: "AIzaSyCStpO-aJPCzWfSPsExMWBhTbuFXAKZFXk",
    authDomain: "adbinder.firebaseapp.com",
    projectId: "adbinder",
    storageBucket: "adbinder.appspot.com",
    messagingSenderId: "409540199830",
    appId: "1:409540199830:web:bc48d9354e79a3712349b7",
    measurementId: "G-WKW3PXS7GP"
  };
  
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.app().functions("europe-west1");
