import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  MapCurrentUserObject,
  MapUserData,
  UserDataModel,
} from "./userDataModel";

export const slice = createSlice({
  name: "userAuth",
  initialState: {
    loading: true,
    userData: {
      signedIn: false,
    } as UserDataModel,
  },

  reducers: {
    SET_LOADING: (state, { payload }: any) => {
      state.loading = payload;
    },
    STORE_CURRENT_USER_OBJECT: (state, { payload }) => {
      state.userData.signedIn = true;
      state.userData = {
        ...state.userData,
        ...MapCurrentUserObject(payload),
      };
    },
    STORE_USER_DATA: (state: any, { payload }) => {
      state.userData = {
        ...state.userData,
        ...MapUserData(payload),
      };
    },
    CLEAR_USER_STORE: (state) => {
      state.userData = {
        signedIn: false,
      } as UserDataModel;
    },
  },
});

export const {
  SET_LOADING,
  STORE_CURRENT_USER_OBJECT,
  STORE_USER_DATA,
  CLEAR_USER_STORE,
} = slice.actions;

export const selectUser = (state: RootState) => {
  return state.userAuth.userData;
};

export const loading = (state: RootState) => {
  return state.userAuth.loading;
};

export default slice.reducer;
