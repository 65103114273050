import React, { useEffect, useState } from "react";
//import { functions } from "../app/firebaseConfig";
import { useAppSelector } from "../app/hooks";
import { selectUser } from "../state/userAuth/authSlice";

export default function SnapchatCallback() {

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorDesc, setErrorDesc] = useState('');

  const user = useAppSelector(selectUser);


  useEffect(() => {
    console.log('use effect pls');

    const params = new URLSearchParams(window.location.search);
    if (params.has('code')) {
      const code = params.get('code') as string;
      //const axiostest = functions.httpsCallable("axiosOAuthGrantHandler");

      fetch("http://localhost:8081/oauthgrantholder", {
        method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({
          code: code,
          service: 'SNAPCHAT',
          redirect_uri: 'https://app.adbinder.com/snapchat-callback',
          type: 'authorization_code',
          url: 'https://cors-anywhere.herokuapp.com/accounts.snapchat.com/login/oauth2/access_token',
          uid: user.uid,
        })
      }).then(() => {
        setLoading(false);
        setSuccess(true);
        
      });

    } else {
      setError(true);
      setErrorDesc('error');
    }
  }, [user.uid]);

  return (
    <div>
      <h1>snapchat callback page function</h1>
      {loading && <p>LOADING</p>}
      {success && <p>SUCCESS</p>}
      {error && <p>ERROR: {errorDesc}</p>}
    </div>
  );
}
