import { auth, db } from "../../app/firebaseConfig";
import {
  CLEAR_USER_STORE,
  SET_LOADING,
  STORE_CURRENT_USER_OBJECT,
  STORE_USER_DATA,
} from "./authSlice";

export const setAuthListener = () => (dispatch: any, state: any) => {
  auth.onAuthStateChanged((user) => {
    if (user) {
      dispatch(STORE_CURRENT_USER_OBJECT(auth.currentUser?.toJSON()));

      dispatch(getUser());
      dispatch(SET_LOADING(false));
    } else {
      dispatch(CLEAR_USER_STORE());
      dispatch(SET_LOADING(false));
    }
  });
};

export const getUser = () => async (dispatch: any, state: any) => {

  db.collection("users").doc(auth.currentUser?.uid).onSnapshot(res => {
    if (res.exists)
    dispatch(STORE_USER_DATA(res.data()));
    else {
    dispatch(logOut());
    alert("ERROR: Could not get user data from server. Logging out");
  }
  });
};

export const login =
  (email: string, password: string) => async (dispatch: any, state: any) => {
    await auth.signInWithEmailAndPassword(email, password);
  };

export const logOut = () => async (dispatch: any, state: any) => {
  auth.signOut();
};

export const createUser =
  (email: string, password: string) => async (dispatch: any, state: any) => {
    const resp = await auth.createUserWithEmailAndPassword(email, password);
    const uid = auth.currentUser?.uid;

    await db.collection("users").doc(uid).set({
      uid,
      email: resp.user?.email,
      access: 10,
    });
  };
