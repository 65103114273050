
import {
  SNAP_SET_LOADING,
  SNAP_SAVE_USER_DATA,
} from "./snapSlice";

//import { functions } from "../../app/firebaseConfig";
import { AppDispatch } from "../../app/store";

export const getAccessToken = () => async (dispatch: any, state: any) => {
  //dispatch(SET_LOADING(state));

  // step 1 - tjek state fra firebase user objektet om der er en access token
  // step 2 - tjek om den access token er brugbar ud fra expires_at
  // step 3 - if nothing in firebase, åben oauth dialogen?

};

export const refreshSnapchat = (
  refresh_token: string,
  uid: string,
) => async (dispatch: any, state: any) => {

  const w = await fetch("http://localhost:8081/refresh", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      refresh_token: refresh_token,
      service: 'SNAPCHAT',
      type: 'refresh_token',
      url: 'https://accounts.snapchat.com/login/oauth2/access_token',
      uid: uid
    })
  })

  const j = await w.json();
  console.log(j);
};

export const getSnapUserData = (access_token: string) => async (dispatch: AppDispatch) => {

  const w = await fetch("http://localhost:8081/request", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      url: 'https://adsapi.snapchat.com/v1/me',
      access_token: access_token,
    })
  })

  const j = await w.json();

  console.log(j);

  dispatch(SNAP_SET_LOADING(false));
  dispatch(SNAP_SAVE_USER_DATA(
    {
      email: j.data.me.email,
      display_name: j.data.me.display_name,
    }
  ))
  //setSnapchatInfo({signedIn: true, name: x.data.me.display_name, email: x.data.me.email});
  //   console.log(x);
  //   }).catch((e) => {
  //   dispatch(SNAP_SET_LOADING(false));
  //   //setSnapchatInfo({signedIn: false, name: '', email: ''});
  //   console.error(e);
  // });
}