import { Button } from "@material-ui/core";
import React from "react";
import { useAppDispatch } from "../app/hooks";
import { logOut } from "../state/userAuth/authFunctions";

export default function Settings() {
  const dispatch = useAppDispatch();

  return (
    <div>
      <Button onClick={() => dispatch(logOut())}>log out</Button>
    </div>
  );
}
