import { Button, Paper } from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";

export default function SideMenu() {
  const history = useHistory();
  return (
    <Paper>
      <div className={"keep-scrolling"}>
        <Button
          style={{ margin: 20 }}
          onClick={() => {
            history.push("dashboard");
          }}
        >
          <h1>LOGO HERE</h1>
        </Button>
        <ul>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link to="/connections">Connections</Link>
          </li>
          <li>
            <Link to="/settings">Settings</Link>
          </li>
        </ul>
      </div>
    </Paper>
  );
}
