export interface UserDataModel {
  signedIn: boolean;
  uid: string;
  email: string;
  authDomain: string;
  displayName: string;
  photoURL: string;
  isAnonymous: boolean;
  emailVerified: boolean;
  access: number;
  services: ServiceModel;
}

export interface ServiceModel {
  SNAPCHAT: OAuthModel,
}

export interface OAuthModel {
  access_token: string,
  refresh_token: string,
  expires_at: number,
}

export function MapCurrentUserObject(object: Object): UserDataModel {
  const userData = object as UserDataModel;
  return {
    uid: userData.uid,
    email: userData.email,
    isAnonymous: userData.isAnonymous,
    authDomain: userData.authDomain,
    displayName: userData.displayName,
    photoURL: userData.photoURL,
    emailVerified: userData.emailVerified,
  } as UserDataModel;
}

export function MapUserData(object: Object): UserDataModel {
  const userData = object as UserDataModel;
  return { access: userData.access,
  services: userData.services, } as UserDataModel;
}


