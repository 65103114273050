import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export const slice = createSlice({
  name: "snapAuth",
  initialState: {
    loading: true,
    snapData: {
      authorized: false,
      display_name: '',
      email: '',
    }
},

  reducers: {
    SNAP_SET_LOADING: (state, { payload }: any) => {
      state.loading = payload;
    },
    SNAP_SAVE_USER_DATA: (state, { payload }: any) => {
      state.snapData.authorized = true;
      state.snapData.email = payload.email;
      state.snapData.display_name = payload.display_name;
    },
    SNAP_CLEAR_USER_STORE: (state) => {
      state.snapData = {
      authorized: false,
      display_name: '',
      email: '',
      }
    },
  },
});

export const {
  SNAP_SET_LOADING,
  SNAP_SAVE_USER_DATA,
  SNAP_CLEAR_USER_STORE,
} = slice.actions;

export const snapLoading = (state: RootState) => {
  return state.snapAuth.loading;
};

export const snapData = (state: RootState) => {
  return state.snapAuth.snapData;
};

export default slice.reducer;