import { useEffect } from "react";
import "./App.css";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Connections from "./components/Connections";
import Settings from "./components/Settings";
import Dashboard from "./components/Dashboard";
import { setAuthListener } from "./state/userAuth/authFunctions";
import { loading, selectUser } from "./state/userAuth/authSlice";
import SignIn from "./components/SignIn";
import SideMenu from "./components/SideMenu";
import SnapchatCallback from "./components/SnapchatCallback";
import { getSnapUserData, refreshSnapchat } from "./state/snapchat/snapFunctions";

function App() {
  const dispatch = useAppDispatch();
  const _loading = useAppSelector(loading);
  const user = useAppSelector(selectUser);

  useEffect(() => {
    dispatch(setAuthListener());
  }, [dispatch]);

  
  useEffect(() => {
    if (user.uid != null) {
      if (user.services != null) {
        if (user.services.SNAPCHAT != null) {
          if (user.services.SNAPCHAT.expires_at - Date.now() < 5 * 300000) {
            console.log('less than 5 minutes left of access token, lets refresh it');
            // SÆT EN BOOL i snap state - REFRESHING = TRUE
            // if refreshing == false
            // kan også være loading bare skal sættes til true / false
            dispatch(refreshSnapchat(user.services.SNAPCHAT.refresh_token, user.uid));
            // SÆT EN BOOL REFRESHING = FALSE
          }
        dispatch(getSnapUserData(user.services.SNAPCHAT.access_token));
        }
      }
    }
  }, [user.uid, user.services, dispatch]);
  
  if (_loading)
  return (
    <div><p>loading</p></div>
  );

  else if (!user.signedIn)
    return (
     <SignIn />
  );

else
  return (
    <div>
        <Router>
          {user.signedIn && <SideMenu />}
          <div
            style={{
              marginLeft: user.signedIn ? 300 : 0,
              padding: user.signedIn ? 20 : 0,
              minHeight: "calc(100vh - 40px)",
              backgroundColor: "#fbfbfb",
            }}
          >
            <Switch>
              <Route
                path="/connections"
                component={Connections}
              ></Route>
              <Route
                path="/settings"
                component={Settings}
              ></Route>
              <Route
                path="/dashboard"
                component={Dashboard}
              ></Route>
              <Route
                path="/snapchat-callback"
                component={SnapchatCallback}
              ></Route>
              <Route path="/" component={Dashboard}></Route>
            </Switch>
          </div>
        </Router>
      
    </div>
  );
}

export default App;
