import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { login } from "../state/userAuth/authFunctions";
import { selectUser } from "../state/userAuth/authSlice";
import { Box, Button, TextField } from "@material-ui/core";

export default function SignIn() {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log(user);
    console.log(user.signedIn);
  }, [user]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#eee",
      }}
    >
      <div>
        <Box
          boxShadow={2}
          style={{ backgroundColor: "#fff", padding: 48, width: 500 }}
        >
          <h3>Sign in to your account</h3>
          <div style={{ height: 16 }}></div>

          <div style={{ textAlign: "center" }}>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
            />

            <div style={{ height: 8 }}></div>
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              fullWidth
            />
            <div style={{ height: 16 }}></div>

            <Button
              onClick={async () => {
                try {
                  await dispatch(login("bjorn@asdkals.dk2", "qweqwe"));
                } catch (e) {
                  alert("ERROR: " + e.message);
                }
              }}
            >
              sign in
            </Button>
          </div>
        </Box>
        <p style={{ marginTop: 16 }} color="primary">
          Don't have an account? <a href="./Register">Sign up</a>
        </p>
      </div>
      {user.signedIn && <Redirect to={"/dashboard"} />}
    </div>
  );
}
