import { Grid, makeStyles, Paper } from "@material-ui/core";
import { useAppSelector } from "../app/hooks";
import { selectUser } from "../state/userAuth/authSlice";
import { snapLoading, snapData } from "../state/snapchat/snapSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function snapchatOAuth() {
  const base = "https://accounts.snapchat.com/login/oauth2/authorize";
  let url = base + "?response_type=code";
  url += "&client_id=b5ccb34d-78bb-4289-a396-862553c2ba9e";
  url += "&redirect_uri=https://app.adbinder.com/snapchat-callback";
  url += "&scope=snapchat-marketing-api";
  url += "&state=adbinder-completely-random-string-123";

  console.log(url);
  var newWindow = window.open(url, "_blank", "height=600,width=450");
  newWindow?.focus();
}

function tiktokOAuth() {
  const base = "https://ads.tiktok.com/marketing_api/auth";
  let url = base + "?response_type=code";
  url += "&app_id=7017700383543787522";
  url += "&redirect_uri=https://app.adbinder.com/tiktok-callback";

  console.log(url);
  var newWindow = window.open(url, "_blank", "height=600,width=450");
  newWindow?.focus();
}

function testRequests() {
  fetch('http://localhost:8081/test',
    { method: 'GET' }).then(val => {
      val.json()
        .then(
          json => {
            console.log(json)
          })
    }).catch(e => { console.log(e); });


  fetch('http://localhost:8081/test-two', {
    method: 'POST',
    body: JSON.stringify({
      test: 'test',
      testx: 'testx',
    }),
  }).then(val => {
    val.json()
      .then(
        json => {
          console.log(json)
        })
  }).catch(e => { console.log(e); });
}

export default function Connections() {
  const classes = useStyles();
  const user = useAppSelector(selectUser);
  const snapState = useAppSelector(snapLoading);
  const snapDataState = useAppSelector(snapData);

  return (
    <div>
      <div>
        <h1>{user.email}</h1>
        <h1>snap {snapState ? 'loading' : 'not loading'}</h1>
        <h1>snap {snapDataState.authorized ? 'authorized' : 'not authorized'}</h1>
        <h1>---</h1>
        <h1>snap email: {snapDataState.authorized && snapDataState.email}</h1>
        <h1>snap name: {snapDataState.authorized && snapDataState.display_name}</h1>

      </div>

      <div>
        <h1>{user.email}</h1>
        <h1>tiktok</h1>
        <h1>tiktok</h1>
        <h1>---</h1>
        <h1>tiktok email:</h1>
        <h1>tiktok name:</h1>

      </div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => snapchatOAuth()}
            >
              <div style={{ height: 32 }}></div>
              <div
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <p>SNAPCHAT OAUTH</p>
              </div>
              <div style={{ height: 32 }}></div>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => tiktokOAuth()}
            >
              <div style={{ height: 32 }}></div>
              <div
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <p>TIKTOK OAUTH</p>
              </div>
              <div style={{ height: 32 }}></div>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => testRequests()}
            >
              <div style={{ height: 32 }}></div>
              <div
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <p>TEST BUTTON</p>
              </div>
              <div style={{ height: 32 }}></div>
            </div>
          </Paper>
        </Grid>


      </Grid>

    </div>
  );
}
